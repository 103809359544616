/**
 * @type {import('vue-router').RouteConfig[]}
 */
export default [
    {
        name: 'customer.dashboard',
        path: '/customers/:customerId/dashboard',
        component: () => import('./CustomerDashboard.vue'),
        redirect: { name: 'customer.dashboard.products' },
        children: [
            {
                name: 'customer.dashboard.products',
                path: '/customers/:customerId/dashboard/products',
                component: () => import('./views/CustomerProductList.vue'),
            },
            {
                name: 'customer.dashboard.product-single',
                path: '/customers/:customerId/dashboard/products/:customerProductId',
                component: () => import('./views/CustomerProductSingle.vue'),
            },
            {
                name: 'customer.dashboard.proposal-list',
                path: '/customers/:customerId/dashboard/proposals',
                component: () => import('./views/CustomerProposalList.vue'),
            },
            {
                name: 'customer.dashboard.proposal-single',
                path: '/customers/:customerId/dashboard/proposals/:proposalId',
                component: () => import('./views/CustomerProposalSingle.vue'),
            },
            {
                name: 'customer.dashboard.order-list',
                path: '/customers/:customerId/dashboard/orders',
                component: () => import('./views/CustomerOrderList.vue'),
            },
        ]
    }
]