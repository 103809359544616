import Api from "@/lib/Api";

export default {
    namespaced: true,
    state: {
        loading: false,
        data: []
    },
    mutations: {
        setLoading(state, loading) {
            state.loading = loading;
        },
        setData(state, data) {
            state.data = data;
        }
    },
    actions: {
        async load({ state, commit }) {
            if (state.loading) return

            commit('setLoading', true)

            const response = await Api.getUsers()

            if (response.error) {
                commit('setLoading', false)
                return
            }

            commit('setData', response.message)
            commit('setLoading', false)
        }
    },
    getters: {
        sellers: state => state.data.filter(user => user.role === 'seller'),
        active: state => state.data.filter(user => !user.filed),
    }
}