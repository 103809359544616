import Vue from "vue";
import VueRouter from "vue-router";
import { i18n } from "@/plugins/i18n";
import { appConfig } from "@/plugins/appConfig";
import mainRoutes from "./routes";

Vue.use(VueRouter);

const routes = []

const files = require.context("@/modules", true, /routes.js$/);

files.keys().map(key => files(key)).map(m => m.default).filter(Boolean).forEach(moduleRoutes => {
    routes.push(...moduleRoutes)
})

routes.push(...mainRoutes)

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes
});


router.beforeEach(async (to, from, next) => {
    const authRequired = to.meta.layout === "dashboard" || to.meta.auth;

    i18n.locale = to.meta.locale || appConfig.language

    const loggedIn = localStorage.getItem("token");

    if (!loggedIn && authRequired && !to.meta.public && to.name !== "Login") {
        next("/login");
    } else {
        next();
    }
});

export default router;
