<template>
    <div>
        <v-navigation-drawer
            class="drawer"
            v-model="drawer"
            :clipped="$vuetify.breakpoint.lgAndUp"
            width="270"
            app
            dark
            color="galaxy"
        >
            <router-link
                to="/"
                class="d-block my-10"
            >
                <v-img
                    :src="$appConfig.images.drawerLogo"
                    width="200"
                    height="53"
                    contain
                    class="mx-auto"
                />
            </router-link>

            <v-list
                dense
                expand
            >
                <template v-for="(item, i) in links">
                    <v-list-group
                        v-if="item.children"
                        :value="true"
                        :key="i"
                        :prepend-icon="item.icon"
                        active-class="white--text"
                    >
                        <template #activator>
                            <v-list-item-title>{{ item.label }}</v-list-item-title>
                        </template>

                        <v-list-item
                            v-for="(child, ci) in item.children"
                            :key="ci"
                            :to="child.to"
                            class="white--text"
                            exact
                        >
                            <v-list-item-icon>
                                <span class="mx-auto">
                                    -
                                </span>
                            </v-list-item-icon>

                            <v-list-item-title>
                                {{ child.label }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-group>

                    <v-list-item
                        v-else
                        :key="`item-${i}`"
                        :to="item.to"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.label }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>

        <v-main>
            <v-app-bar
                color="white"
                :clipped-left="$vuetify.breakpoint.lgAndUp"
                elevation="0"
                class="app_bar"
            >
                <v-app-bar-nav-icon
                    @click.stop="drawer = !drawer"
                    color="mercury"
                />
        
                <span class="header-title">{{ $appConfig.title }}</span>

                <v-spacer />

                <!-- <v-menu offset-y>
                    <template #activator="{ on }">
                        <v-btn
                            icon
                            v-on="on"
                            color="mercury"
                        >
                            <v-icon>mdi-bell</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense>
                        <v-list-item>
                            <v-list-item-title>
                                {{ $t('noEntity', [$tc('notification', 2)]) }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu> -->

                <v-menu offset-y>            
                    <template #activator="{ on }">
                        <v-btn
                            text
                            v-on="on"
                            height="48"
                        >
                            <div class="d-flex align-center">
                                <v-avatar
                                    size="32"
                                    class="mr-md-4"
                                >
                                    <v-img
                                        v-if="currentUser?.profile_picture"
                                        :src="currentUser?.profile_picture"
                                    />
                                    <div
                                        v-else
                                        class="w-full h-full primary d-flex justify-center"
                                    >
                                        <v-icon color="white">
                                            mdi-account
                                        </v-icon>
                                    </div>
                                </v-avatar>
  
                                <div class="mercury--text text-left text-none d-none d-lg-block">
                                    <div class="font-ubuntu font-weight-bold text-subtitle-2">
                                        {{ currentUser?.name }}
                                    </div>
                                </div>
                            </div>
                        </v-btn>     
                    </template>

                    <v-list>
                        <v-list-item @click="logout">
                            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>          
            </v-app-bar>

            <v-divider />

            <template v-if="loading">
                <v-progress-linear
                    indeterminate
                    color="primary"
                />
            </template>

            <v-container fluid>
                <slot />
            </v-container>
        </v-main>
    </div>
</template>

<script>
import Api from "@/lib/Api";

export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        links: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        drawer: false,
    }),

    computed: {
        currentUser() {
            return this.$store.state.currentUser
        },
    },
    methods: {       
        logout() {
            Api.logout();
        },
    },
    mounted() {
        this.$vuetify.breakpoint.lgAndUp ? (this.drawer = true) : (this.drawer = false);
    },
};
</script>
