import { i18n } from "@/plugins/i18n";

export default {
    namespaced: true,
    state: {
        statuses: [
            'new',
            'pending_approval',
            'archived',
            'in_negotiation',
            'pending_approval',
            'approved',
            'archived',
        ],
        versionStatus: [
            {
                text: i18n.t('new'),
                value: 'new',
                
                // chip props
                color: 'blue',
                dark: true,
            },
            {
                text: i18n.t('inNegotiation'),
                value: 'in_negotiation',
                
                // chip props
                color: 'purple',
                dark: true,
            },
            {
                text: i18n.t('pendingApproval'),
                value: 'pending_approval',
                
                // chip props
                color: 'warning',
                dark: true,
            },
            {
                text: i18n.t('approved'),
                value: 'approved',
                
                // chip props
                color: 'success',
                dark: true,
            },
            {
                text: i18n.t('archived'),
                value: 'archived',
                
                // chip props
                color: 'gray',
                dark: true,
            }
        ]
    }
}
