import { i18n } from "@/plugins/i18n";

export default {
    namespaced: true,
    state: {
        orderProposalStatus: [
            {
                text: i18n.t('unknown'),
                value: 'unknown',
                // color: 'grey',
            },
            {
                text: i18n.t('inProduction'),
                value: 'inProduction',
                color: 'purple',
                dark: true,
            },
            {
                text: i18n.t('seaRoute'),
                value: 'seaRoute',
                color: 'blue',
                dark: true,
            },
            {
                text: i18n.t('roadRoute'),
                value: 'roadRoute',
                color: 'orange',
                dark: true,
            },
            {
                text: i18n.t('assembling'),
                value: 'assembling',
                color: 'pink',
                dark: true,
            },
            {
                text: i18n.t('delivered'),
                value: 'delivered',
                color: 'green',
                dark: true,
            }
        ],
    }
}