import { i18n } from "@/plugins/i18n";

export default {
    namespaced: true,
    state: {
        assemblyStatus: [
            {
                text: i18n.t('unknown'),
                value: 'unknown',
            },
            {
                text: i18n.t('inProgress'),
                value: 'inProgress',
                color: 'purple',
                dark: true,
            },
            {
                text: i18n.t('done'),
                value: 'done',
                color: 'green',
                dark: true,
            },
        ],
    }
}