import { i18n } from "@/plugins/i18n";

export default [
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/Login/Login.vue"),
    },
    {
        path: "/",
        name: "Dashboard",
        component: () => import("../views/Dashboard/Dashboard.vue"),
        meta: {
            layout: "dashboard",
        },
    },
    {
        path: "/my-profile",
        name: "MyProfile",
        component: () => import("../views/MyProfile/MyProfile.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.t("myProfile"),
        },
    },
    {
        path: "/users",
        name: "Users",
        component: () => import("../views/Users/Users.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("user", 2),
        },
    },
    {
        path: "/products",
        name: "ProductList",
        component: () => import("../views/ProductList/ProductList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("product", 2),
        },
    },
    {
        path: "/products/new",
        name: "ProductNew",
        component: () => import("../views/ProductSingle/ProductSingle.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.t("newEntity", [i18n.tc("product", 1)]),
        },
    },
    {
        path: "/products/:productId",
        name: "ProductSingle",
        component: () => import("../views/ProductSingle/ProductSingle.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("product", 1),
        },
    },
    {
        path: "/minimum-markups",
        name: "MinimumMarkupList",
        component: () => import("../views/MinimumMarkupList/MinimumMarkupList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("markup", 2),
        },
    },
    {
        path: "/settings",
        name: "Settings",
        component: () => import("../views/Settings/Settings.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("setting", 2),
        },
    },
    {
        path: "/proposals",
        name: "ProposalList",
        component: () => import("../views/ProposalList/ProposalList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("proposal", 2),
        },
    },
    {
        path: "/proposals/new",
        name: "ProposalNew",
        component: () => import("../views/ProposalNew/ProposalNew.vue"),
        meta: {
            layout: "public",
        },
    },
    {
        path: "/shipping",
        name: "Shipping",
        component: () => import("../views/Shipping/Shipping.vue"),
        meta: {
            layout: "public",
        },
    },
    {
        path: "/proposals/:proposalId",
        name: "ProposalSingle",
        props: true,
        component: () => import("../views/ProposalSingle/ProposalSingle.vue"),
        meta: {
            layout: "dashboard",
        },
    },
    {
        path: "/proposals/:proposalId/versions/new",
        name: "ProposalVersionNew",
        component: () => import("../views/ProposalVersionSingle/ProposalVersionSingle.vue"),
        meta: {
            layout: "dashboard",
        },
    },
    {
        path: "/proposals/:proposalId/versions/:versionId/edit",
        name: "ProposalVersion",
        component: () => import("../views/ProposalVersionSingle/ProposalVersionSingle.vue"),
        meta: {
            layout: "dashboard",
        },
    },
    {
        path: "/proposals/:proposalId/versions",
        name: "PublicVersionList",
        component: () => import("../views/PublicVersionList/PublicVersionList.vue"),
        meta: {
            layout: "public",
        },
    },
    {
        path: "/proposals/:proposalId/versions/:versionId",
        name: "PublicVersionSingle",
        component: () => import("../views/PublicVersionSingle/PublicVersionSingle.vue"),
        meta: {
            layout: "public",
            title: i18n.tc("proposal", 1),
        },
    },
    {
        path: "/customers",
        name: "CustomerList",
        component: () => import("../views/CustomerList/CustomerList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("client", 2),
        },
    },
    {
        path: "/customers/:customerId",
        name: "CustomerSingle",
        component: () => import("../views/CustomerSingle/CustomerSingle.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("client"),
        },
    },
    {
        path: "/customers/:customerId/products/:customerProductId",
        name: "CustomerProductSingle",
        component: () => import("../views/CustomerProductSingle/CustomerProductSingle.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("product"),
        },
    },
    {
        path: "/suppliers",
        name: "SupplierList",
        component: () => import("../views/SupplierList/SupplierList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("supplier", 2),
        },
    },
    {
        path: "/orders",
        name: "OrderList",
        component: () => import("../views/OrderList/OrderList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("order", 2),
        },
    },
    {
        path: "/orders/new",
        name: "OrderNew",
        component: () => import("../views/OrderNew/OrderNew.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.t("newEntity", [i18n.tc("order", 1)]),
        },
    },
    {
        path: "/orders/:orderId",
        name: "OrderSingle",
        component: () => import("../views/OrderSingle/OrderSingle.vue"),
        meta: {
            layout: "dashboard",
            auth: true
        },
    },
    {
        path: "/orders/:orderId/suppliers/:supplierId/document",
        name: "OrderSupplierDocument",
        component: () => import("../views/OrderSupplierDocument/OrderSupplierDocument.vue"),
        meta: {
            layout: "public",
            locale: "en-US",
        },
    },
    {
        path: "/orders/:orderId/suppliers/:supplierId/labels",
        name: "OrderSupplierLabelList",
        component: () => import("../views/OrderSupplierLabelList/OrderSupplierLabelList.vue"),
        meta: {
            layout: false,
            public: true,
            locale: "en-US",
        },
    },
    {
        path: "/categories",
        name: "CategoryList",
        component: () => import("../views/CategoryList/CategoryList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("category", 2),
        },
    },
    {
        path: "/categories/:categoryId",
        name: "CategorySingle",
        component: () => import("../views/CategorySingle/CategorySingle.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("category"),
        },
    },
    {
        path: "/subcategories",
        name: "SubcategoryList",
        component: () => import("../views/SubcategoryList/SubcategoryList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("subcategory", 2),
        },
    },
    {
        path: '/proposals-per-customer',
        name: 'ProposalsPerCustomer',
        component: () => import('../views/ProposalsPerCustomer/ProposalsPerCustomer.vue'),
        meta: {
            layout: "dashboard",
            title: i18n.t('proposalsPerClient'),
        }
    },
    {
        path: "/customers/:customerId/proposals",
        name: "CustomerProposalList",
        component: () => import("../views/CustomerProposalList/CustomerProposalList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.tc("proposal", 2),
        },
    },
    {
        path: "/product-groups",
        name: "ProductGroupList",
        component: () => import("../views/ProductGroupList/ProductGroupList.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.t("groups"),
        },
    },
    {
        path: "/product-groups/:productGroupId",
        name: "ProductGroupSingle",
        component: () => import("../views/ProductGroupSingle/ProductGroupSingle.vue"),
        meta: {
            layout: "dashboard",
            title: i18n.t("group"),
        },
    },
    {
        path: '/order-settings',
        component: () => import('../views/OrderSettings/OrderSettings.vue'),
        meta: {
            layout: 'dashboard',
        }
    },
    {
        path: '/orders/:orderId/proposals/:proposalId/shipping',
        component: () => import('../views/OrderShipping/OrderShipping.vue'),
        meta: {
            layout: 'public',
            public: true,
        }
    },
    // keep this route as the last one
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: () => import("../views/404/404.vue"),
        meta: {
            layout: false,
            title: i18n.t("pageNotFound"),
        },
    }
];