import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from '@/store'
import VueMask from "v-mask";
import "@/assets/main.scss";
import "@/assets/global.css";

import './plugins/appConfig'
import './plugins/components'
import './plugins/globals'

import { i18n } from "./plugins/i18n";

Vue.config.productionTip = false;
Vue.use(VueMask);

const app = new Vue({
    router,
    vuetify,
    store,
    i18n,
    render: (h) => h(App),
})

app.$mount("#app");
