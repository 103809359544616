<template>
    <div>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template #activator="{ on, attrs }">
                <v-text-field
                    v-model="text"
                    :label="label"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    :hide-details="hideDetails"
                    :disabled="disabled"
                    :clearable="clearable"
                    @click:clear="model = null"
                    v-bind="attrs"
                    v-on="on"
                />
            </template>

            <v-date-picker
                v-model="model"
                locale="pt"
                :range="range"
                @change="save"
            />
        </v-menu>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array],
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        format: {
            type: String,
            default: 'dd/MM/yyyy',
        },
        range: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        menu: false,
    }),
    computed: {
        model: {
            get () {
                return this.value
            },
            set (val) {
                this.$emit('input', val)
            },
        },
        text: {
            get(){
                if (!this.model) return

                if (this.range && this.model.length !== 2) return

                if (this.range && this.model.length === 2) {
                    return this.$date.format(this.model[0], this.format) + ' - ' + this.$date.format(this.model[1], this.format)
                }

                return this.$date.format(this.model, this.format)
            },
            set(){},
        }
    },
    methods: {
        save (date) {
            this.$refs.menu.save(date)
        },
    },
}
</script>