import Api from "@/lib/Api";

export default {
    namespaced: true,
    state: {
        data: []
    },
    mutations: {
        setData(state, data) {
            state.data = data;
        }
    },
    actions: {
        async load({ commit }){
            const response = await Api.getSettings()

            if (response.error) return

            commit('setData', response.message)
        },
        async set({ dispatch }, payload) {            
            const response = await Api.createOrUpdateSetting(payload)

            if (response.error) return

            await dispatch('load')
        }
    },
    getters: {
        getByName: state => (name, defaultValue) => {
            const setting = state.data.find(setting => setting.name === name)

            return setting ? setting.value : defaultValue
        }
    }
}