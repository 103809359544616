import Vue from "vue";

export const appConfig = {
    title: process.env.VUE_APP_TITLE || 'Plataforma Evogear',
    shortTitle: process.env.VUE_APP_SHORT_TITLE || 'Evogear',

    language: process.env.VUE_APP_LANGUAGE || 'pt-BR',
    currency: process.env.VUE_APP_CURRENCY || 'brl',

    colors: {
        primary: process.env.VUE_APP_COLOR_PRIMARY ||  '#d19c5c',
        galaxy: process.env.VUE_APP_COLOR_GALAXY || '#383838',
        stardust: process.env.VUE_APP_STARDUST || '#383838',
        moon: process.env.VUE_APP_COLOR_MOON || '#383838',
        error: process.env.VUE_APP_COLOR_ERROR || '#ef8989',
        mercury: process.env.VUE_APP_COLOR_MERCURY || '#383838'          
    },

    images: {
        favicon: process.env.VUE_APP_IMAGE_FAVICON_URL || '/favicon.ico',
        login: process.env.VUE_APP_IMAGE_LOGIN_URL || '/evogear-login.png',
        drawerLogo: process.env.VUE_APP_IMAGE_DRAWER_LOGO_URL || '/evogear-logo-drawer.webp',
        toolbarLogo: process.env.VUE_APP_IMAGE_TOOLBAR_LOGO_URL || '/evogear-logo-toolbar.webp',
        labelsLogo: process.env.VUE_APP_IMAGE_LABELS_LOGO_URL || '/evogear-labels-logo.png'
    },

    labels: {
        footerText: process.env.VUE_APP_LABELS_FOOTER_TEXT || 'Desenvolvido no brasil pela EVOGEAR, Produzido na CHINA (PRC)',
    }
}

Vue.prototype.$appConfig = appConfig