import Api from "@/lib/Api";

export default {
    namespaced: true,
    state: {
        value: 0
    },
    mutations: {
        setValue(state, data) {
            state.value = data;
        }
    },
    actions: {
        async load({ commit }){
            const response = await Api.getLastDollar()

            if (response.error) {
                return
            }

            commit('setValue', response.message.value)
        }
    },
}