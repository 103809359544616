<template>
    <div
        ref="root"
        :style="`position:relative;height: ${height}px; width: ${width}px`"
    >
        <canvas ref="canvas" />
    </div>
</template>
<script>
import Chart from 'chart.js/auto';

export default {
    props: {
        options: {
            type: Object,
            required: true,
        },
        height: {
            type: Number,
            default: 400,
        },
        width: {
            type: [Number, String],
            default: '100%',
        },

    },
    data: () => ({
        chart: null,
        resizeObserver: null,
    }),
    methods: {
        load(){
            if (!this.$refs.canvas) return

            if (this.chart) return

            const ctx = this.$refs.canvas.getContext('2d')

            this.chart = new Chart(ctx, this.options)
        }
    },
    mounted(){
        this.load()

    },
    beforeDestroy(){
        if (this.chart) {
            this.chart.destroy()
        }
    }
}
</script>